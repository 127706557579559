.product_square {
  object-fit: contain;
}

.text_square {
  overflow-wrap: break-word;
}

.container {
  width: 100px;
  height: 100px;
  position: relative;
}
