$dropdown-text-color: #777785;
$dropdown-text-color-dirty: #000000;
$grey-background-color: #fbfbfb;
$background-border-color: #e0e0e0;
$inventory-controls-font-size: 13.33px;
$inventory-controls-font-weight: 500;

.top_controls_background {
  background-color: $grey-background-color;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #e0e0e0;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  padding: 32px 32px 20px;
  gap: 30px;
}

.bottom_controls_background {
  background-color: $grey-background-color;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: $background-border-color;
  padding: 22px 16px;
  gap: 10px;
  flex-wrap: wrap;
}

.small_label_text {
  font-weight: 400;
  color: black;
  font-size: 11.11px;
  line-height: 140%;
  display: flex;
  align-items: center;
  margin: 0;
}

.inventory_controls_input {
  flex: none;
  flex-grow: 0;
  font-weight: $inventory-controls-font-weight !important;
  font-size: $inventory-controls-font-size !important;
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  border-color: #777785 !important;
  background-color: transparent !important;
  padding: 6px 28px 6px 12px;
  outline: none;

  &:active {
    border-color: #000000 !important;
  }

  &:focus {
    border-color: #6051fb !important;
  }
}

.inventory_controls_button {
  min-width: 100px;
  font-size: $inventory-controls-font-size !important;
  font-weight: $inventory-controls-font-weight !important;

  &:not([disabled]) {
    color: #7155FF !important;
    border-color: #7155FF !important;

    &:hover {
      color: white !important;
      background-color: #7155FF;
    }
  }
}

.inventory_dropdown_dirty {
  color: $dropdown-text-color-dirty;
}

.inventory_dropdown_clean {
  color: $dropdown-text-color;
}

.loading_spinner {
  animation-timing-function: ease-in-out;
  animation-duration: 1.5s;
}

.category_text {
  font-size: $inventory-controls-font-size !important;
  margin-bottom: 0 !important;
}