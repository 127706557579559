.facebook_preview {
  background-color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular',
    sans-serif;
  margin: 10px auto;
  max-width: 85%;
  border: solid 1px #e3e5e8;

  .ad_header {
    padding: 12px;
    display: flex;
    align-items: center;

    .ad_logo {
        margin: 0 10px 0 0;
        max-height: 50px;
        max-width: 100px;
      }

    .ad_titles {
      .ad_title_one {
        font-weight: 600;
        line-height: 19.3167px;
        a {
          color: #385898;
          font-size: 14px;
        }
      }

      .ad_title_two {
          color: #616770;
          font-size: 12px;
          font-weight: 400;
          line-height: 16.0833px;
        a {
          font-size: 12px;
          color: #616770;
        }

        .fas {
          font-size: 11px;
        }
      }
    }
  }

  .ad_item {
    width: 100%
  }

  .ad_body {
    color: rgb(29, 33, 41);
    font-size: 14px;
    font-weight: 400;
    line-height: 19.3167px;
    margin: 12px;
    white-space: pre-line;
  }

  .ad_base {
    background-color: #f2f3f5;

    &:hover {
      background-color: #eaebec;
    }
  }

  .ad_media {
    width: 100%;
  }

  .ad_media_preview {
    width: 100%;
    padding: 15px !important;
    background-color: #fff;
    display: flex;
  }

  .ad_footer {
    border: solid 1px #e7e9eb;
    padding: 10px 12px 9px 12px;
    display: flex;

    .ad_link {
      color: #606770;
      flex-shrink: 0;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .ad_headline {
      color: #1d2129;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin: 3px 0 0;
      padding-top: 2px;
    }

    .ad_link_title {
      color: rgb(96, 103, 112);
      font-size: 14px;
      line-height: 20px;
      margin-top: 3px;
      max-height: 80px;
      overflow: hidden;
      overflow-wrap: break-word;
      overflow-x: hidden;
      overflow-y: hidden;
      word-break: break-word;
    }
  }

  .ad_reactions {
    width: 100%;
  }

  .ad_desc {
    flex: 0 0 70%;
  }

  .ad_cta_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 30%;

    .ad_link_cta {
      padding: 2px 5px;
      background-color: #eaeff3;
      border-radius: 2px;
      border: 1px solid #c5cace;
      font-size: 12px;
      font-weight: 700;
    }
  }
}
